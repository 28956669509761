import { isEmptyObject } from '@firi/shared';
import { firiMerge } from '@firi/shared/styles';
import { Card, CardSkin } from '@firi/ui-web';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/macro';
import clsx, { ClassValue } from 'clsx';
import { getIconBySanityKey } from 'helpers/icon';
import { resolveArticleUrl } from 'helpers/url';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { ArticleItem } from './ArticlePost';
import { Button } from './Button';
import { HorizontalScroller } from './HorizontalScroller';
import { Typography } from './Typography';

type ArticleShowCaseProps = {
  articles: ArticleItem[];
  colSpan?: ClassValue;
  skin?: CardSkin;
  horizontalScrollerClassName?: string;
};

const svgTypeDefault = ['magnifying', 'newspaper', 'parachute', 'coinwave'];

export const ArticleShowCase = ({
  articles,
  colSpan = 'md:col-span-4',
  skin,
  horizontalScrollerClassName,
}: ArticleShowCaseProps) => {
  return (
    <HorizontalScroller
      className={firiMerge('lg:grid gap-4 grid-cols-12', horizontalScrollerClassName)}
    >
      {articles.map((article, index) => {
        if (isEmptyObject(article)) return null;
        const { blogPitch, title, _id, slug: articleSlug, categories: articleCategories } = article;

        const router = useRouter();
        const locale = router.locale || '';

        const currentSlug = articleSlug;
        const currentCategories = articleCategories;

        const url =
          resolveArticleUrl({ slug: currentSlug, categories: currentCategories }, locale) || '';
        const Illustration = getIconBySanityKey(svgTypeDefault[index]);

        return (
          <Card
            className={firiMerge(
              'min-w-[300px] lg:min-w-min lg:mr-4 last:mr-4 first:ml-4 lg:first:ml-0 flex flex-col justify-between',
              { 'bg-blue-50  dark:bg-card-dark-3 lg:bg-white  lg:dark:bg-card-dark-1': !skin },
              colSpan,
            )}
            as="article"
            key={title}
            skin={skin}
          >
            <div>
              <div className="mb-5 line-clamp-3">
                <Typography as="h1" size="lg" color="blue">
                  {title}
                </Typography>
              </div>
              <div className="mb-10 line-clamp-4">
                <Typography>{blogPitch}</Typography>
              </div>
            </div>
            <div className="flex justify-between items-end">
              <div>
                <Link href={url} locale={router.locale} passHref>
                  <a>
                    <Button skin="outlined" aria-label={t`Read more`}>
                      <Trans>Read more</Trans>
                    </Button>
                  </a>
                </Link>
              </div>
              <div className="w-24 h-24 md:w-30 md:h-30">
                <Illustration size="none" />
              </div>
            </div>
          </Card>
        );
      })}
    </HorizontalScroller>
  );
};
