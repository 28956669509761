import { SOME_URLS } from '@firi/shared/constants';
import { select, t } from '@lingui/macro';
import Head from 'next/head';
import { useRouter } from 'next/router';

export const SEOHomePage = () => {
  const router = useRouter();

  const schemaOrgWebPage = {
    '@context': 'https://schema.org',
    '@type': ['Organization', 'CurrencyConversionService', 'InvestmentOrDeposit'],
    url: select(router.locale || 'no', {
      en: 'https://www.firi.com',
      no: 'https://www.firi.com/no',
      dk: 'https://www.firi.com/dk',
    }),
    name: 'Firi',

    identifier: {
      '@type': 'PropertyValue',
      propertyID: 'Company Number',
      value: '919932465',
      id: 'https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=919932465',
    },
    alternateName: 'Miraiex',
    legalName: 'Firi AS',
    foundingDate: '2017',
    founders: {
      '@type': 'Person',
      name: ['Thuc Hoang', 'Øyvind Kvanes'],
    },
    sameAs: [
      'https://no.wikipedia.org/wiki/Firi',
      ...Object.values(SOME_URLS),
      'https://play.google.com/store/apps/details?id=com.mirai.miraiex',
      'https://apps.apple.com/no/app/miraiex/id1542108249',
    ],
    logo: {
      '@type': 'ImageObject',
      caption: t`Firi - Nordic largest crypto exchange`,
      url: 'https://firi.com/images/logos/Firi-Logomark_Blue.png',
    },
    description: t`Buy and sell bitcoin and other cryptocurrencies for NOK, safely and easily. We are registered with Finanstilsynet, have a Vipps login, use BankID and a Norwegian bank for deposits and withdrawals.`,
    address: {
      '@type': 'PostalAddress',
      addressCountry: 'Norway',
      addressRegion: 'Oslo',
      postalCode: '0250',
      streetAddress: 'Grundingen 2',
    },
    image: {
      '@id': 'https://www.firi.com',
    },
    email: 'support@firi.com',
  };

  return (
    <Head>
      <script
        key="structured-seo"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaOrgWebPage) }}
      />
    </Head>
  );
};
