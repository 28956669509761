import { Trans } from '@lingui/macro';
import { ArticleItem } from 'components/ArticlePost';
import { ArticleShowCase } from 'components/ArticleShowCase';
import { Button } from 'components/Button';
import ContentWrapper from 'components/ContentWrapper';
import SanityContent from 'components/SanityContent';
import { SEOHomePage } from 'components/StructuredData/SEOHomePage';
import { Typography } from 'components/Typography';
import { sanityCategoryIds } from 'constants/sanityCategoryIds';
import { findSanityLanguageByLocale } from 'helpers/language';
import { getLocalizedUrl } from 'helpers/url';
import { getClient } from 'lib/sanity/sanity.server';
import { GetServerSideProps } from 'next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Page } from 'types/schema';

const Home = ({
  data,
  abTestVariants,
}: {
  data: Page & { articles: ArticleItem[] };
  abTestVariants: Record<string, string>;
}) => {
  const { articles = null } = data || {};
  const router = useRouter();

  return (
    <>
      <SEOHomePage />
      <main className="overflow-hidden">
        {data?.content && <SanityContent blocks={data.content} />}
        {articles && (
          <section className="md:my-24 my-12 pb-12 md:pb-24">
            <ContentWrapper>
              <div className="flex justify-between items-center mb-4 md:mb-8 gap-x-4">
                <Typography as="h2" size="xl">
                  <Trans>Get started</Trans>
                </Typography>

                <Link
                  href={
                    getLocalizedUrl('/articles', router.locale, router.query) +
                    '?category=how-to-invest'
                  }
                  locale={router.locale}
                  passHref
                >
                  <a>
                    <Button skin="outlined" aria-label="See all" eventAction="Blog">
                      <Trans>See all</Trans>
                    </Button>
                  </a>
                </Link>
              </div>
              <ArticleShowCase articles={articles} colSpan="md:col-span-3" skin={'white'} />
            </ContentWrapper>
          </section>
        )}
      </main>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async ({
  preview = false,
  locale = '',
  req: { cookies },
  res,
}) => {
  res.setHeader('Cache-Control', 'public, s-maxage=600, stale-while-revalidate');
  const data = await getClient(preview).fetch(
    `*[_type =="page" && slug.predefined == "index"  && __i18n_lang == $locale][0] {...,
      content[]{..., externalArticles[]->{..., source->}},
      "articles": *[_type == "post" && __i18n_lang == $locale && !(_id in path("drafts.**")) && references($categoryId)  && !hideOnLanding] | order(publishedAt desc)[0..3]  {
        _id,
        title, 
        blogPitch,
        slug,
        alt,
        categories[]->
      }
    }`,
    {
      locale: findSanityLanguageByLocale(locale),
      categoryId: sanityCategoryIds['how-to-invest'],
    },
  );

  return {
    props: { data },
  };
};

export default Home;
